.light-theme {
  --light-gray: var(--chakra-colors-gray-200);
  --gray: var(--chakra-colors-gray-300);
  --blue700: var(--chakra-colors-blue-600);
  --blue600: var(--chakra-colors-blue-500);
  --brand600: var(--chakra-colors-brand-400);
  --brand200: var(--chakra-colors-brand-200);
  --blue500: var(--chakra-colors-gray-400);
  --blue400: var(--chakra-colors-gray-300);
  --blue300: var(--chakra-colors-gray-200);
  --blue200: var(--chakra-colors-gray-200);
  --blue100: var(--chakra-colors-gray-100);
  --monthBackground: var(--chakra-colors-white);
  --text: var(--chakra-colors-black);
  --negative-text: var(--chakra-colors-white);
}

.dark-theme {
  --light-gray: var(--chakra-colors-gray-600);
  --gray: var(--chakra-colors-gray-500);
  --blue700: var(--chakra-colors-blue-600);
  --blue600: var(--chakra-colors-blue-300);
  --brand600: var(--chakra-colors-brand-400);
  --brand200: var(--chakra-colors-brand-200);
  --blue500: var(--chakra-colors-gray-500);
  --blue400: var(--chakra-colors-gray-600);
  --blue300: var(--chakra-colors-gray-700);
  --blue200: var(--chakra-colors-gray-600);
  --blue100: var(--chakra-colors-gray-800);
  --monthBackground: var(--chakra-colors-gray-700);
  --text: var(--chakra-colors-gray-200);
  --negative-text: var(--chakra-colors-black);
}

.react-datepicker {
  font-family: unset !important;
  font-size: 0.9rem !important;
  border-color: var(--light-gray) !important;
  background-color: var(--monthBackground) !important;
}

.react-datepicker-popper {
  z-index: 10;
}

.react-datepicker__navigation--next--with-time:not(
    .react-datepicker__navigation--next--with-today-button
  ) {
  right: 90px !important;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  top: 8px !important;
}

.react-datepicker__navigation--previous {
  border-right-color: var(--blue400) !important;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: var(--blue500) !important;
}

.react-datepicker__navigation--next {
  border-left-color: var(--blue400) !important;
}

.react-datepicker__navigation--next:hover {
  border-left-color: var(--blue500) !important;
}

.react-datepicker__header {
  background-color: var(--blue100) !important;
}

.react-datepicker__header,
.react-datepicker__time-container {
  border-color: var(--blue300) !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit !important;
  font-weight: 600 !important;
  color: var(--text) !important;
}

.react-datepicker__month {
  background-color: var(--monthBackground) !important;
  margin: 0 !important;
  padding: 0.4rem !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  margin: 0 1px 0 0 !important;
  height: auto !important;
  padding: 7px 10px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: var(--blue200) !important;
}

.react-datepicker__day {
  color: var(--text) !important;
}

.react-datepicker__day:hover {
  background: var(--brand200) !important;
  color: var(--negative-text) !important;
}

.react-datepicker__day-name {
  color: var(--text) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: var(--brand600) !important;
  font-weight: normal !important;
  color: var(--negative-text) !important;
}

.react-datepicker__day--outside-month,
.react-datepicker__month-text--outside-month {
  color: var(--light-gray) !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: var(--blue700) !important;
}

.react-datepicker__close-icon::after {
  background-color: unset !important;
  border-radius: unset !important;
  font-size: 1.5rem !important;
  font-weight: bold !important;
  color: var(--light-gray) !important;
  height: 20px !important;
  width: 20px !important;
}

.react-datepicker__close-icon::after:hover {
  color: var(--gray) !important;
}
