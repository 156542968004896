.introjs-tooltip-header {
  padding: 8px 12px 0 12px;
}

.introjs-tooltiptext {
  padding: 8px 12px;
}

.onboarding-tooltip-dark {
  background-color: var(--chakra-colors-gray-800);
}

.onboarding-tooltip-dark .introjs-tooltip-header .introjs-skipbutton {
  color: var(--chakra-colors-gray-500);
}

.onboarding-tooltip-dark .introjs-tooltiptext {
  color: var(--chakra-colors-slate-100);
}

.onboarding-tooltip-dark .introjs-arrow.top {
  border-bottom-color: var(--chakra-colors-gray-800);
}

.onboarding-tooltip-dark .introjs-arrow.left {
  border-right-color: var(--chakra-colors-gray-800);
}

.onboarding-tooltip-dark .introjs-helperNumberLayer {
  font-size: 0.75rem;
  color: var(--chakra-colors-slate-400);
}

.onboarding-tooltip-dark .introjs-tooltipbuttons {
  border: none;
}

.onboarding-highlight {
  z-index: 100000000;
}

.introjs-tooltipbuttons > .introjs-button {
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: var(--chakra-radii-md);
  font-weight: var(--chakra-fontWeights-semibold);
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  height: var(--chakra-sizes-4);
  min-width: var(--chakra-sizes-4);
  font-size: var(--chakra-fontSizes-sm);
  padding-inline-start: var(--chakra-space-3);
  padding-inline-end: var(--chakra-space-3);
  text-shadow: none;
}

.introjs-tooltipbuttons > .introjs-button:first-child {
  border: 1px solid;
  border-color: var(--chakra-colors-gray-700);
  color: var(--chakra-colors-gray-700);
  background: var(--chakra-colors-transparent);
}

.introjs-tooltipbuttons > a:last-child {
  background: var(--chakra-colors-brand-200);
  color: var(--chakra-colors-gray-700);
}
