/*
https://stripe.com/docs/js/element/the_element_container

.StripeElement--complete
.StripeElement--empty
.StripeElement--focus
.StripeElement--invalid
.StripeElement--webkit-autofill (Chrome and Safari only)
*/

.StripeElement {
  padding: 1rem;
  border: 1px solid rgb(215, 219, 224);
  border-radius: 4px;
  outline: none;
  color: rgb(54, 73, 98);
  background-color: transparent;
  font-size: 0.9rem;
}

.StripeElement--invalid {
  border: 1px solid #bf1650;
}
